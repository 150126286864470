import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import useGraphList from '../../hooks/useGraphList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { fetchGraphList } = useGraphList();

  const receiveMessage = event => {
    fetchGraphList();
    console.log('event', event);
    if (event.origin !== 'http://example.org:8080') return;

    // ...
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', receiveMessage, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <h1>Test v3</h1>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
