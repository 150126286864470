import axios from 'axios';
// import setupAxiosInterceptors from './axios-interceptor';

// export const apiUrl = process.env.REACT_APP_API_URL;

export const apiUrl = process.env.REACT_APP_API_URL;


export const API = axios.create({
  baseURL: apiUrl
});

// setupAxiosInterceptors(API);

export const apiAuth = {
  token: '714d09b9385d49e9d6c595d903b924f325bf53d3',
  ts: 1586546747
};

export const request = async ({ method = 'GET', url, data, config }) => {
  const response = await API({
    method,
    url,
    data,
    ...config
  });
  return { data: response.data };
};

export const requestGet = async ({ url, data }) =>
  request({ method: 'GET', url, data });
export const requestPost = async ({ url, data, config }) =>
  request({ method: 'POST', url, data, config });

export default { apiAuth, API, request, requestGet, requestPost };
