import { useState } from 'react';
import { requestGet } from '../config/api';

const useBreakout = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  const fetchGraphList = async () => {
    setLoading(true);
    setError('');

    try {
      const data = await requestGet({ url: '/itineraries/graphlist?cacheBuster=1591947246549&date=2020-06-12' });
      if (data.response === 200) {
        setData(data);
      } else {
        setError('Error');
      }
    } catch (e) {
      setError('Error');
    }
    setLoading(false);
  };

  return { fetchGraphList, data, loading, error };
};

export default useBreakout;
